import Vue from 'vue'
import Vuex from 'vuex'
import { getInstance } from '@/auth/auth0-plugin'
import { formatParty } from '@/config/party-formatting'

// API
import DocumentTag from '@/axios/document-tag'
import Party from '@/axios/party'
import Search from '@/axios/search'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    root_key: 'ipro_portal',
    statuses: [
      { id: 'CURRENT', name: 'Current' },
      { id: 'ARCHIVE', name: 'Archive' }
    ],
    doc_tags: [],
    userPartyId: null,
    userRelatedParties: [],
    activeUserRelatedParties: [],
    retailCategoryIds: ['RETAILER_REPORT'],
    vendorCategoryIds: ['VENDOR_REPORT'],
    adminCategoryIds: ['ADMIN_REPORT'],
    adGroups: [],
    tenantStores: []
  },
  getters: {
    root_key (state) {
      return state.root_key
    },
    doc_tags (state) {
      return state.doc_tags
    },
    categories (state) {
      const tag = state.doc_tags.find(t => t?.id === 'CATEGORY')
      return tag?.children || []
    },
    retailCategories (state, getters) {
      return getters.categories.filter(c => state.retailCategoryIds.includes(c?.id))
    },
    vendorCategories (state, getters) {
      return getters.categories.filter(c => state.vendorCategoryIds.includes(c?.id))
    },
    adminCategories (state, getters) {
      return getters.categories.filter(c => state.adminCategoryIds.includes(c?.id))
    },
    statuses (state) {
      return state.statuses
    },
    userPartyId (state) {
      return state.userPartyId
    },
    userRelatedParties (state) {
      return state.userRelatedParties
    },
    activeUserRelatedParties (state) {
      return state.activeUserRelatedParties
    },
    userRelatedPartyIds (state) {
      if (state.userRelatedParties.length > 0) {
        return state.userRelatedParties.map(party => party.id)
      }
      return []
    },
    activeUserRelatedPartyIds (state) {
      if (state.activeUserRelatedParties.length > 0) {
        return state.activeUserRelatedParties.map(party => party.id)
      }
      return []
    },
    adGroups (state) {
      return state.adGroups
    },
    tenantStores (state) {
      return state.tenantStores
    }
  },
  mutations: {
    setDocumentTags (state, payload) {
      state.doc_tags = payload
    },
    setUserPartyId (state, payload) {
      state.userPartyId = payload
    },
    setUserRelatedParties (state, payload) {
      state.userRelatedParties = payload
    },
    setActiveUserRelatedParties (state, payload) {
      state.activeUserRelatedParties = payload
    },
    setAdGroups (state, payload) {
      state.adGroups = payload
    },
    setTenantStores (state, payload) {
      state.tenantStores = payload
    }
  },
  actions: {
    async getDocumentTags (context) {
      if (context.getters.doc_tags.length === 0) {
        try {
          const res = await DocumentTag.getList()
          context.commit('setDocumentTags', res.data)
        } catch (err) {
          console.error("Could not retrieve document tags.", err)
          throw err
        }
      }
    },
    async getUserRelatedParties (context) {
      const userParties = context.getters.userRelatedParties
      if (userParties.length > 0) return

      const authService = getInstance()
      const { email } = authService.user
      const includeStores = authService.userRoles.some(roleName => {
        return roleName?.toLowerCase()?.includes('retail')
          || ['promo.coordinator', 'pce-owner'].includes(roleName)
      })
      const limitStores = authService.permissions.includes('retail:related_stores')

      const findOwnersFromChildren = authService.userRoles.some(roleName => { return roleName === "promo.coordinator" })

      const extendedAccess = includeStores // same criteria, but new var for readability

      let extendedRelatedParties = []
      let relatedParties = []
      try {
        const [res, extended_res] = await Promise.allSettled([
          Party.getUserProfile(
            email,
            includeStores,
            limitStores,
            findOwnersFromChildren
          ),
          Party.getUserProfile(
            email,
            includeStores,
            limitStores,
            findOwnersFromChildren,
            extendedAccess
          ),
        ])

        if (res?.value?.data?.length > 0 || extended_res?.value?.data?.length > 0) {
          relatedParties = res.value.data.map(p => formatParty(p))
          extendedRelatedParties = extended_res.value.data.map(p => formatParty(p))
        }
        context.commit('setActiveUserRelatedParties', relatedParties)
        return context.commit('setUserRelatedParties', extendedRelatedParties)
      } catch (err) {
        console.error(err)
      }
    },
    async getAdGroups (context) {
      if (context.getters.adGroups.length > 0) return
      let adGroups = []
      try {
        const { data } = await Search.customerSearch('AD_GROUP')
        if (data?.length > 0) {
          adGroups = data.flatMap(party => {
            if (party.party_type === 'AD_GROUP') {
              return { ...party, id: party.party_id, name: party.party_name }
            }
            return []
          })
        }
        return context.commit('setAdGroups', adGroups)
      } catch (err) {
        console.error(err)
      }
    },
    async getTenantStores (context) {
      let stores = []
      const authService = getInstance()
      if (authService.tenant !== 'pricechopper') return
      try {
        const { data } = await Party.getAllStoresForTenant()
        if (data?.length > 0) {
          stores = data.flatMap(store => {
            return { ...store, id: store.store_party_id, name: `#${store.wholesaler_store_number || ''} ${store.store_name}` }
          })
        }
        return context.commit('setTenantStores', stores)
      } catch (err) {
        console.error(err)
      }
    }
  }
})
