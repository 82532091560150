export const displayAlert = {
  data () {
    return {
      alert: false,
      alertType: 'warning',
      alertMessage: '',
      errors: [],
      messages: [],
      successes: [],
      timeout: -1,
      needConfirmation: false
    }
  },
  watch: {
    errors: {
      handler (newValue) {
        if (newValue) {
          if (this.errors.length >= 1) {
            this.emitAlert(true, 'warning', 'Please correct the following errors: ', this.errors)
          }
        }
      }
    },
    successes: {
      handler (newValue) {
        if (newValue) {
          if (this.successes.length >= 1) {
            this.emitAlert(true, 'success', 'Success: ', this.successes)
          }
        }
      }
    }
  },
  methods: {
    emitAlert (showAlert, alertType, alertMessage, messages, timeout, needConfirmation = false) {
      const payload = {
        alert: showAlert,
        alertType: alertType,
        alertMessage: alertMessage,
        messages: messages,
        timeout: timeout,
        needConfirmation: needConfirmation
      }
      this.eventbus.$emit('alertdialog', payload)
    },
    resetAlert () {
      this.errors.length = 0
      this.successes.length = 0
      const payload = {
        alert: false,
        alertType: 'success',
        alertMessage: '',
        messages: []
      }
      this.eventbus.$emit('alertdialog', payload)
    },
    setNextFn (fn) {
      this.eventbus.$emit('nextFn', fn)
    },
    handleRejected (rejected) {
      const errs = rejected.map(e => e.reason?.response?.data?.message || e.reason.message)
      this.emitAlert(true, 'warning', `The following errors have occurred: ${errs.join(', ')}`)
    },
    handleError (err) {
      if (Array.isArray(err)) {
        return this.handleRejected(err)
      }
      console.error(err)
      let message = err?.response?.data?.message || err.message

      if (message.toLowerCase().includes("network error")) {
        const regex = /network error.*/i // Remove 'Network Error' from message. Replace with 'Please refresh the page'
        message = message.replace(regex, "Please refresh the page.")
        console.error("Unable to connect to backend. Page refresh may be needed.")
      }
      this.emitAlert(true, 'warning', message)
    }
  }
}
