var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: {
        top: "",
        "content-class": "pa-0",
        color: _vm.alertType,
        timeout: _vm.timeout,
      },
      model: {
        value: _vm.alert,
        callback: function ($$v) {
          _vm.alert = $$v
        },
        expression: "alert",
      },
    },
    [
      _vm.needConfirmation
        ? _c(
            "v-alert",
            {
              staticClass: "my-0",
              attrs: {
                border: "left",
                dense: "",
                prominent: "",
                type: "warning",
                "retain-focus": "",
              },
              model: {
                value: _vm.alert,
                callback: function ($$v) {
                  _vm.alert = $$v
                },
                expression: "alert",
              },
            },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c("v-col", [_vm._v(" " + _vm._s(_vm.alertMessage) + " ")]),
                  _c(
                    "v-col",
                    { staticClass: "ml-auto", attrs: { cols: "auto" } },
                    [
                      _c(
                        "span",
                        [
                          _c(
                            "v-btn",
                            { staticClass: "mr-4", on: { click: _vm.confirm } },
                            [_vm._v(" Confirm ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { outlined: "" },
                              on: { click: _vm.cancel },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-alert",
            {
              staticClass: "my-0",
              attrs: {
                dense: "",
                border: "left",
                dismissible: "",
                prominent: "",
                type: _vm.alertType,
                "retain-focus": "",
              },
              model: {
                value: _vm.alert,
                callback: function ($$v) {
                  _vm.alert = $$v
                },
                expression: "alert",
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.alertMessage) + " "),
              _vm._l(_vm.messages, function (message, key) {
                return _c("div", { key: key, staticClass: "errorItem" }, [
                  _vm._v(_vm._s(message)),
                ])
              }),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }