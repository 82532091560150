export const userAccess = {
  computed: {
    entities () {
      return ['document', 'document_party', 'document_file']
    },
    tenant () {
      return this.$auth.tenant
    },
    userData () {
      return this.$auth.user
    },
    userRoles () {
      return this.$auth.userRoles || []
    },
    userPermissions () {
      return this.$auth.permissions || []
    },
    userRelatedParties () {
      return this.$store.getters.userRelatedParties
    },
    userRelatedPartyIds () {
      return this.$store.getters.userRelatedPartyIds
    },
    userSearchPartyIds () {
      // if user has unrestricted store access, exclude stores to prevent overloading api
      // pce is an exception bc they only have ~50 stores max
      if (this.isRetailUser && !this.limitAccessByRelatedStores && this.$auth.tenant !== 'pricechopper') {
        return this.nonStoreRelatedPartyIds
      }
      return this.isVendorUser ? this.userVendorPartyIds : this.userRelatedPartyIds
    },
    userStores () {
      return this.userRelatedParties.flatMap(party => {
        return party?.party_type_constant === 'STORE' ? party : []
      })
    },
    userStorePartyIds () {
      return this.userRelatedParties.flatMap(party => {
        return party?.party_type_constant === 'STORE' ? party.id : []
      })
    },
    userAdGroupPartyIds () {
      return this.userRelatedParties.flatMap(party => {
        return party?.party_type_constant === 'AD_GROUP'
          ? party.id
          : []
      })
    },
    userVendorPartyIds () {
      return this.userRelatedParties.flatMap(party => {
        return party?.party_type_constant === 'VENDOR' ? party.id : []
      })
    },
    nonStoreRelatedPartyIds () {
      return this.userRelatedPartyIds.filter(id => id && this.userStorePartyIds.includes(id) === false)
    },
    hasPortalAccess () {
      const readScopes = this.entities.map(entity => `${entity}:read`)
      return readScopes.every(scope => this.hasScope(scope))
    },
    adminRoles () {
      return ["ipro-portal.admin", "pce-admin", "jbg-admin"]
    },
    isAdminUser () {
      return this.adminRoles.some((role) => this.userRoles.includes(role))
    },
    edgeManagerRole() {
      return ["ipro-portal.retailer-edge-manager"]
    },
    isEdgeManager() {
      return this.userRoles.some((role) => this.edgeManagerRole.includes(role))
    },
    hasAdminPartyAccess () {
      return this.hasScope('partner:admin_parties')
    },
    isNonAdminUser () {
      return (!this.isAdminUser && !this.hasAdminPartyAccess)
    },
    hasOrderGridAccess () {
      if (this.isAdminUser) return true
      return (this.$auth.tenant === 'pricechopper') ? this.hasScope('partner:order_grid') : this.hasRetailWriteAccess
    },
    hasDocumentUploadAccess () {
      return this.isAdminUser || this.hasScope('document:upload')
    },
    hasRetailWriteAccess () {
      const scopes = ['ad_planner:create', 'ad_planner:update']
      return scopes.every(scope => this.hasScope(scope))
    },
    limitAccessByRelatedStores () {
      return this.hasScope('retail:related_stores')
    },
    isRetailUser () {
      return this.isNonAdminUser && this.userRoles.some(roleName => {
        return roleName?.toLowerCase()?.includes('retail')
         || this.isPromoCoordinator
      })
    },
    isVendorUser () {
      return this.isNonAdminUser && this.userRoles.some(roleName => {
        return roleName?.toLowerCase()?.includes('vendor')
      }) && !this.isPromoCoordinator
    },
    isPromoCoordinator () {
      return (this.isNonAdminUser && this.userRoles.includes('promo.coordinator'))
    },
    isJBGRetailer () {
      return (this.$auth.tenant === 'jbg') && !this.isAdminUser && this.userRoles.includes('jbg-retailer')
    },
    isPCEOwner () {
      return (this.$auth.tenant === "pricechopper") && !this.isAdminUser && this.userRoles.includes('pce-owner')
    },
    isProductCatalogRetailer () {
      return this.isJBGRetailer && this.userStores.some(store => {
        return (store?.attributes?.PRODUCT_CATALOG_STORE === 'True')
      })
    },
    categories () {
      return this.$store.getters.categories
    },
    retailCategories () {
      return this.$store.getters.retailCategories
    },
    vendorCategories () {
      return this.$store.getters.vendorCategories
    },
    adminCategories () {
      return this.$store.getters.adminCategories
    },
    userCategories () {
      if (this.$auth.tenant === 'pricechopper') {
        return this.pceUserCategories
      }
      if (this.isRetailUser) {
        return this.isPromoCoordinator
          ? [...this.retailCategories, ...this.vendorCategories]
          : this.retailCategories
      } else if (this.isVendorUser) {
        return this.vendorCategories
      }
      return this.categories
    },
    pceUserCategories () {
      if (!this.isAdminUser) {
        const categories = []
        if (this.hasScope('document:retailer_report')) {
          categories.push(...this.retailCategories)
        }
        if (this.hasScope('document:vendor_report')) {
          categories.push(...this.vendorCategories)
        }
        if (this.hasScope('document:admin_report')) {
          categories.push(...this.adminCategories)
        }
        return categories
      }
      return this.categories
    }
  },
  methods: {
    hasScope (scope) {
      return this.userPermissions.includes(scope)
    },
  }
}
