<template>
  <v-container class="pa-0 ma-0" fluid v-resize="onResize">
    <!-- <v-card flat class="pb-0 mb-0">
      <v-card-text class="ma-0 pa-0"> -->
        <v-data-table
          dense
          :items-per-page="pageSize"
          :expanded.sync="expanded"
          :headers="headers"
          :height="tableSize"
          :items.sync="documents"
          :loading="loading"
          :search="filterText"
          item-key="id"
          loading-text="Loading, please wait..."
          no-data-text="No files to show"
          no-results-text="No results found"
          hide-default-footer
          fixed-header
          @click:row="getDocDetails">
          <template v-slot:top>
            <v-toolbar flat color="toolbar" height="75" style="cursor: default">
              <span>
                <v-toolbar-title>Files</v-toolbar-title>
              </span>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-col cols="3" class="pl-0">
                <PartySelect
                  :selectedParties.sync="selectedParties"
                  @remove="removeParty">
                </PartySelect>
              </v-col>
                <DocumentTags
                  :filteredIds="filteredIds"
                  @cancelLoad="() => loading = false"
                  v-model="tags"
                />
              <v-col cols="3">
                <v-text-field
                  v-model="filterText"
                  label="Filter"
                  placeholder="Filter page by file name or description"
                  prepend-inner-icon="mdi-text-search-variant"
                  hide-details="auto"
                  background-color="input"
                  dense
                  outlined
                  clearable
                />
              </v-col>
            </v-toolbar>
          </template>
           <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="(headers.length)">
              <span v-for="(meta, metaKey) in item.metadata" :key="metaKey">
                <strong v-text="metaKey"></strong> {{meta}}
                <v-divider class="ml-2 mr-2" vertical />
              </span>
              <template v-if="!item.metadata && item.parties.length === 0">
                There is no metadata attached to this document
              </template>
            </td>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <span v-if="isAdminUser">
              {{ item.name }}
            </span>
            <v-tooltip v-else top open-delay="400">
              <template v-slot:activator="{ on, attrs }">
                <span style="cursor: pointer" v-on="on" v-bind="attrs">
                  {{ item.name }}
                </span>
              </template>
              <span>Click to Download</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="text-no-wrap">
              <v-icon class="mr-2" @click.stop="downloadFile(item.id)">
                mdi-file-download-outline
              </v-icon>
              <v-btn v-if="isAdminUser" icon @click.stop="deleteDoc(item)">
                <v-icon class="mr-2">mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.tags.category`]="{ item }">
            <span v-if="item.tags.category">
              {{ getCategoryName(item.tags.category) }}
            </span>
          </template>
          <template v-slot:[`item.tags.subcategory`]="{ item }">
            <span v-if="item.tags.subcategory">
              {{ getSubcategoryName(item.tags.category, item.tags.subcategory) }}
            </span>
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <v-toolbar flat color="toolbar">
                <v-spacer></v-spacer>
                <v-col cols="2" sm="3" style="max-width:150px;">
                  <v-select
                    class="mt-8"
                    dense
                    :items="pageSizes"
                    label="Items Per Page"
                    v-model="pageSize"
                    :menu-props="{ top: true, offsetY: true, maxHeight: 500 }">
                  </v-select>
                </v-col>
                <v-btn
                  icon
                  large
                  @click="previous(); onPageChange();"
                  :disabled="disablePrevious"
                  class="mx-2">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <small>Page {{ page }}</small>
                <v-btn icon large
                  @click="next(); onPageChange();"
                  :disabled="disableNext"
                  class="mx-2">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </v-toolbar>
          </template>
        </v-data-table>
      <!-- </v-card-text>
    </v-card> -->
  </v-container>
</template>
<script lang="js">
// mixins
import { displayAlert } from '@/mixins/alert'
import { fullwidth } from '@/mixins/fullwidth'
import { userAccess } from '@/mixins/user-access'
import { paging } from '@/mixins/paging'

// api
import document from '@/axios/document.ts'
import file from '@/axios/file.ts'

// third party helpers
import { format } from 'date-fns'

// components
import DocumentTags from '@/components/DocumentTags.vue'
import PartySelect from '@/components/PartySelect.vue'

export default {
  name: 'DocList',
  data: () => ({
    loading: true,
    docDetails: {},
    documents: [],
    selectedParties: [],
    items: [],
    filteredUsers: {
      'arg-admin': ['RETAILER_AD_PLANNER', 'RETAILER_ORDER_HISTORY', 'RETAILER_TPR_REPORT', 'RETAILER_PCE_POSTED'],
      'pce-ad-builder': ['RETAILER_ORDER_HISTORY'],
      'pce-marketing-partner': ['RETAILER_ORDER_HISTORY', 'RETAILER_TPR_REPORT', 'RETAILER_BILLING_REPORT', 'AD_GROUP_BILLING_REPORT'],
      'pce-store-retailer': ['AD_GROUP_BILLING_REPORT'],
      'pce-ad-group-retailer': ['AD_GROUP_BILLING_REPORT'],
      'jbg-retailer': ['RETAILER_BILLING_REPORT']
    },
    filterText: '',
    tags: {
      category: '',
      subcategory: '',
      status: ''
    },
    expanded: [],
    headers: [
      { sortable: false, class: 'accent', filterable: false },
      { text: 'Name', class: 'accent white--text', value: 'name', sortable: true, filterable: true },
      { text: 'Description', class: 'accent white--text', value: 'description', sortable: true, filterable: true },
      { text: 'Category', class: 'accent white--text', value: 'tags.category', sortable: true, filterable: false },
      { text: 'Subcategory', class: 'accent white--text', value: 'tags.subcategory', sortable: true, filterable: false },
      { text: 'Last Updated', class: 'accent white--text', value: 'audit.updated_on', sortable: true, filterable: false },
      { text: 'Actions', class: 'accent white--text', value: 'actions', sortable: false, filterable: false }
    ]
  }),
  mixins: [displayAlert, fullwidth, userAccess, paging],
  components: { DocumentTags, PartySelect },
  watch: {
    selectedParties: {
      handler (newValue) {
        if (newValue?.length > 0) {
          sessionStorage.setItem('selected_parties', JSON.stringify(newValue))
        } else {
          sessionStorage.removeItem('selected_parties')
        }
        this.searchDocuments()
      },
      deep: true
    },
    userCategories: {
      handler (newValue) {
        if (newValue?.length > 0) {
          this.initTags()
        }
      },
      deep: true
    },
    tags: {
      handler (newValue) {
        if (newValue) {
          sessionStorage.setItem('selected-tags', JSON.stringify(newValue))
          this.searchDocuments()
        }
      },
      deep: true
    },
    userRelatedPartyIds: {
      handler (newValue) {
        if (newValue?.length > 0 && this.isNonAdminUser && this.selectedParties.length === 0) {
          this.searchDocuments()
        }
      },
      deep: true
    }
  },
  created () {
    this.nonTableHeight = 240
    if (sessionStorage.selected_parties) {
      this.selectedParties = JSON.parse(sessionStorage.selected_parties)
    }
    this.tableChanged = this.tableChange(this.searchDocuments)
    if (this.doc_tags.length > 0) {
      this.initTags()
    }
  },
  computed: {
    filteredIds() {
      const userRole = this.userRoles.find(role => role in this.filteredUsers)
      return userRole ? this.filteredUsers[userRole] : []
    },
    selectedPartyIds () {
      if (this.selectedParties.length > 0) {
        return this.selectedParties.map(party => (party.party_id || party.id))
      }
      return []
    },
    doc_tags () {
      return this.$store.getters.doc_tags
    },
    disablePrevious () {
      return ((this.page - 1) * this.pageSize) === 0
    },
    disableNext () {
      return this.documents.length < this.pageSize
    }
  },
  methods: {
    removeParty (index) {
      this.selectedParties.splice(index, 1)
    },
    onResize () {
      this.tableHeight = window.innerHeight - 240
    },
    onPageChange () {
      this.filterText = ''
    },
    initTags () {
      const stored = sessionStorage.getItem('selected-tags')
      if (stored) {
        return this.tags = JSON.parse(stored)
      }
      this.tags = this.getFilteredTags(this.tags)
    },
    getFilteredTags (tags) {
      const validCat = this.userCategories.find(c => c?.id === tags.category)
      if (!validCat) {
        tags.category = this.userCategories[0].id
      }
      const subcategories = this.getSubcategories(tags.category)
      if (!subcategories.find(c => c.id === tags.subcategory)) {
        tags.subcategory = ''
      }
      if (!tags.status) tags.status = 'CURRENT'
      return tags
    },
    async searchDocuments () {
      let partyIds = this.userPartyId ? [this.userPartyId] : []
      if (this.selectedPartyIds.length > 0) {
        partyIds.push(...this.selectedPartyIds)
      }
      if (partyIds.length === 0 && this.isNonAdminUser) {
        if (this.userRelatedPartyIds.length === 0) return
        partyIds = this.buildPartyIdParams()
      }
      this.loading = true
      const tags = this.buildTagParams()
      let documents = []
      try {
        const res = await document.search(partyIds, tags, this.from, this.pageSize)
        if (res.data?.length > 0) {
          if (this.filteredIds?.length > 0) {
            documents = res.data.filter(doc => !this.filteredIds.includes(doc.tags.subcategory))
          } else {
            documents = res.data
          }
          documents = documents.map(doc => {
            doc.audit.created_on = this.formatTimestamp(doc.audit.created_on)
            doc.audit.updated_on = this.formatTimestamp(doc.audit.updated_on)
            return doc
          })
        }
        this.documents = documents
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    buildPartyIdParams () {
      let partyIds = this.userSearchPartyIds
      if (this.tags.subcategory === 'RETAILER_SRP_ALERT') {
        // 250 limit to avoid overloading the API
        partyIds = this.userStorePartyIds.slice(0, 250)
      } else if (this.tags.category === 'VENDOR_REPORT' && this.isPromoCoordinator) {
        // promo coordinators have unrestricted vendor access
        partyIds = []
      }
      return partyIds
    },
    buildTagParams () {
      if (this.tags.subcategory && !this.tags.category) {
        this.tags.subcategory = null
      }
      let tags = {}
      const entries = Object.entries(this.tags).filter(([k, v]) => {
        if (v !== '' && v !== null) return [k, v]
      })
      if (entries.length > 0) {
        tags = Object.fromEntries(entries)
      }
      return tags
    },
    async deleteDoc (item) {
      try {
        await document.delete(item.id)
        this.emitAlert(true, 'success', `${item.name} has been successfully deleted`)
        this.searchDocuments()
      } catch (err) {
        this.handleError(err)
      }
    },
    async downloadFile (id) {
      try {
        const res = await file.get(id)
        const url = res.data?.url
        window.open(url, '_blank')
      } catch (err) {
        this.handleError(err)
      }
    },
    expandRow (value, $event) {
      const rowIdx = this.expanded.indexOf(value)
      if (rowIdx >= 0) {
        this.expanded.splice(rowIdx, 1)
      } else {
        this.expanded.push(value)
        this.getDocDetails($event, value.id)
      }
    },
    getDocDetails (doc) {
      if (this.isAdminUser) {
        return this.$router.push({
          name: 'Edit',
          query: { id: doc.id }
        })
      } else {
        this.downloadFile(doc.id)
      }
    },
    formatTimestamp (time) {
      time = new Date(time)
      return format(time, this.$config.timestamp)
    },
    getCategoryName (categoryId) {
      const category = this.categories.find(c => c.id === categoryId)
      return category?.name || ''
    },
    getSubcategoryName (categoryId, subcategoryId) {
      const subcategories = this.getSubcategories(categoryId)
      const subcategory = subcategories.find(s => s.id === subcategoryId)
      return subcategory?.name || ''
    },
    getSubcategories (catId) {
      const category = this.categories.find(c => c.id === catId)
      if (category) {
        const subcategoryTag = category.children.find(c => c.id === 'SUBCATEGORY')
        return subcategoryTag?.children || []
      }
      return []
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep tbody tr.v-data-table__expanded__content {
  background: #efefef;
  box-shadow: none !important;
}
</style>
