var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      staticClass: "pa-0 ma-0",
      attrs: { fluid: "" },
    },
    [
      _c("v-data-table", {
        attrs: {
          dense: "",
          "items-per-page": _vm.pageSize,
          expanded: _vm.expanded,
          headers: _vm.headers,
          height: _vm.tableSize,
          items: _vm.documents,
          loading: _vm.loading,
          search: _vm.filterText,
          "item-key": "id",
          "loading-text": "Loading, please wait...",
          "no-data-text": "No files to show",
          "no-results-text": "No results found",
          "hide-default-footer": "",
          "fixed-header": "",
        },
        on: {
          "update:expanded": function ($event) {
            _vm.expanded = $event
          },
          "update:items": function ($event) {
            _vm.documents = $event
          },
          "click:row": _vm.getDocDetails,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function () {
                return [
                  _c(
                    "v-toolbar",
                    {
                      staticStyle: { cursor: "default" },
                      attrs: { flat: "", color: "toolbar", height: "75" },
                    },
                    [
                      _c("span", [_c("v-toolbar-title", [_vm._v("Files")])], 1),
                      _c("v-divider", {
                        staticClass: "mx-4",
                        attrs: { inset: "", vertical: "" },
                      }),
                      _c(
                        "v-col",
                        { staticClass: "pl-0", attrs: { cols: "3" } },
                        [
                          _c("PartySelect", {
                            attrs: { selectedParties: _vm.selectedParties },
                            on: {
                              "update:selectedParties": function ($event) {
                                _vm.selectedParties = $event
                              },
                              "update:selected-parties": function ($event) {
                                _vm.selectedParties = $event
                              },
                              remove: _vm.removeParty,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("DocumentTags", {
                        attrs: { filteredIds: _vm.filteredIds },
                        on: { cancelLoad: () => (_vm.loading = false) },
                        model: {
                          value: _vm.tags,
                          callback: function ($$v) {
                            _vm.tags = $$v
                          },
                          expression: "tags",
                        },
                      }),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Filter",
                              placeholder:
                                "Filter page by file name or description",
                              "prepend-inner-icon": "mdi-text-search-variant",
                              "hide-details": "auto",
                              "background-color": "input",
                              dense: "",
                              outlined: "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.filterText,
                              callback: function ($$v) {
                                _vm.filterText = $$v
                              },
                              expression: "filterText",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "expanded-item",
              fn: function ({ headers, item }) {
                return [
                  _c(
                    "td",
                    { attrs: { colspan: headers.length } },
                    [
                      _vm._l(item.metadata, function (meta, metaKey) {
                        return _c(
                          "span",
                          { key: metaKey },
                          [
                            _c("strong", {
                              domProps: { textContent: _vm._s(metaKey) },
                            }),
                            _vm._v(" " + _vm._s(meta) + " "),
                            _c("v-divider", {
                              staticClass: "ml-2 mr-2",
                              attrs: { vertical: "" },
                            }),
                          ],
                          1
                        )
                      }),
                      !item.metadata && item.parties.length === 0
                        ? [
                            _vm._v(
                              " There is no metadata attached to this document "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: `item.name`,
              fn: function ({ item }) {
                return [
                  _vm.isAdminUser
                    ? _c("span", [_vm._v(" " + _vm._s(item.name) + " ")])
                    : _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", "open-delay": "400" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticStyle: { cursor: "pointer" },
                                          },
                                          "span",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" " + _vm._s(item.name) + " ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("Click to Download")])]
                      ),
                ]
              },
            },
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "div",
                    { staticClass: "text-no-wrap" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.downloadFile(item.id)
                            },
                          },
                        },
                        [_vm._v(" mdi-file-download-outline ")]
                      ),
                      _vm.isAdminUser
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteDoc(item)
                                },
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v("mdi-trash-can-outline"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.tags.category`,
              fn: function ({ item }) {
                return [
                  item.tags.category
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getCategoryName(item.tags.category)) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.tags.subcategory`,
              fn: function ({ item }) {
                return [
                  item.tags.subcategory
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getSubcategoryName(
                                item.tags.category,
                                item.tags.subcategory
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("v-divider"),
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "toolbar" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        {
                          staticStyle: { "max-width": "150px" },
                          attrs: { cols: "2", sm: "3" },
                        },
                        [
                          _c("v-select", {
                            staticClass: "mt-8",
                            attrs: {
                              dense: "",
                              items: _vm.pageSizes,
                              label: "Items Per Page",
                              "menu-props": {
                                top: true,
                                offsetY: true,
                                maxHeight: 500,
                              },
                            },
                            model: {
                              value: _vm.pageSize,
                              callback: function ($$v) {
                                _vm.pageSize = $$v
                              },
                              expression: "pageSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            icon: "",
                            large: "",
                            disabled: _vm.disablePrevious,
                          },
                          on: {
                            click: function ($event) {
                              _vm.previous()
                              _vm.onPageChange()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                        1
                      ),
                      _c("small", [_vm._v("Page " + _vm._s(_vm.page))]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            icon: "",
                            large: "",
                            disabled: _vm.disableNext,
                          },
                          on: {
                            click: function ($event) {
                              _vm.next()
                              _vm.onPageChange()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }