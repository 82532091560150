<template>
  <v-snackbar top v-model="alert" content-class="pa-0" :color="alertType" :timeout="timeout">
    <v-alert
      v-if="needConfirmation"
      v-model="alert"
      border="left"
      dense
      class="my-0"
      prominent
      type="warning"
      retain-focus>
      <v-row align="center">
        <v-col>
          {{ alertMessage }}
        </v-col>
        <v-col cols="auto" class="ml-auto">
          <span>
            <v-btn class="mr-4" @click="confirm">
              Confirm
            </v-btn>
            <v-btn outlined @click="cancel">
              Cancel
            </v-btn>
          </span>
        </v-col>
      </v-row>
    </v-alert>
    <v-alert v-else
      dense class="my-0" border="left" dismissible prominent :type="alertType" v-model="alert" retain-focus>
        {{alertMessage}}
        <div class="errorItem" v-for="(message, key) in messages" :key="key">{{message}}</div>
    </v-alert>
  </v-snackbar>
</template>

<script lang="js">
// mixins
import { displayAlert } from '@/mixins/alert'

export default {
  name: 'alertbar',
  created () {
    this.eventbus.$on('alertdialog', (data) => {
      this.alert = data.alert
      this.alertType = data.alertType
      this.alertMessage = data.alertMessage
      this.messages = data.messages
      this.needConfirmation = data.needConfirmation

      if (data.timeout) {
        this.timeout = 3000
      }

      window.setTimeout(() => {
        this.timeout = -1
      }, 3100)
    })
    this.eventbus.$on('nextFn', data => {
      this.nextFn = data
    })
  },
  mixins: [displayAlert],
  methods: {
    confirm () {
      this.alert = false
      if (this.nextFn) {
        this.nextFn()
      }
    },
    cancel () {
      this.alert = false
      this.needConfirmation = false
    }
  }
}
</script>

<style scoped lang="scss">

</style>
