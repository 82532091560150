<template>
  <v-row dense>
    <template v-for="tag in doc_tags">
      <v-col :cols="$props.cols" :key="tag.id">
        <v-autocomplete
          v-model="value[`${tag.id.toLowerCase()}`]"
          :label="tag.name"
          :items="tag.id.toLowerCase() === 'category' ? userCategories : tag.children"
          prepend-inner-icon="mdi-filter-outline"
          item-text="name"
          item-value="id"
          hide-details="auto"
          background-color="input"
          dense
          :clearable=" tag.id.toLowerCase() === 'category' ? (tenant === 'pricechopper' ? isAdminUser : true) : true"
          outlined>
        </v-autocomplete>
      </v-col>
      <template v-if="filterChildTags(tag.id).length > 0">
        <v-col :cols="$props.cols" v-for="childTag in filterChildTags(tag.id)" :key="childTag.id">
          <v-autocomplete
            :label="childTag.name"
            v-model="value[childTag.id.toLowerCase()]"
            :items="childTag.children"
            prepend-inner-icon="mdi-filter-outline"
            item-text="name"
            item-value="id"
            hide-details="auto"
            background-color="input"
            dense
            outlined
            clearable>
            </v-autocomplete>
        </v-col>
      </template>
    </template>
    <v-col :cols="$props.cols">
      <v-autocomplete
        label="Status"
        v-model="value.status"
        prepend-inner-icon="mdi-filter-outline"
        :items="statuses"
        item-text="name"
        item-value="id"
        dense
        outlined
        hide-details="auto"
        background-color="input"
        clearable>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>
<script lang="js">
import { userAccess } from '@/mixins/user-access'
import { displayAlert } from '@/mixins/alert'
export default {
  name: 'DocumentTags',
  mixins: [userAccess, displayAlert],
  props: {
    value: {
      type: [Object, undefined]
    },
    cols: {
      type: [String, Object, undefined],
      default: null
    },
    filteredIds: {
      type: Array,
    }
  },
  async beforeCreate () {
    try {
      await this.$store.dispatch('getDocumentTags')
    } catch (err) {
      this.handleError(err)
      this.$emit('cancelLoad')
    }
  },
  computed: {
    doc_tags () {
      return this.$store.getters.doc_tags
    },
    statuses () {
      return this.$store.getters.statuses
    }
  },
  methods: {
    getChildTags (tagId) {
      const tag = this.doc_tags.find(t => t.id === tagId)
      const tagValue = tag?.children?.find(c => c.id === this.value[`${tagId.toLowerCase()}`])
      return tagValue?.children || []
    },
    userRoleFilteredIds () {
      return this.filteredIds[this.userRole] || []
    },
    filterChildTags (parentTagId) {
      const childTags = this.getChildTags(parentTagId)
      if (this.filteredIds?.length > 0) {
        const filteredChildTags = JSON.parse(JSON.stringify(childTags))
        const userFilteredIds = this.filteredIds
        return filteredChildTags.map((childTag) => {
          if (childTag.id === 'SUBCATEGORY') {
            childTag.children = childTag.children.filter(child => !userFilteredIds.includes(child.id))
          }
          return childTag
        })
      }
      return childTags
    }
  }
}
</script>
